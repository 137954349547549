<template>
  <div class="topbar">
    <div class="topbar-menu">
      <img src="../assets/img/icon/menu.png" />
      <label class="principal-contenido">Menu</label>
    </div>
    <img class="topbar-logo" src="../assets/img/logo.png" />
    <div class="topbar-img-container">
      <div>
        <label v-if="usuario != null" class="topbar-usuario-nombre">{{
          usuario.nombre + " " + usuario.apellido
        }}</label>
        <label class="topbar-logout" @click="logout()">Cerrar sesión</label>
      </div>
      <div class="topbar-img">
        <img src="../assets/img/login/supervisor.png" />
      </div>
    </div>
    <div class="topbar-navbar">
      <div
        class="topbar-navbar-row"
        :class="{ 'topbar-navbar-row-active': seccion == 'eventos' }"
        @click="irA('/')"
      >
        <img
          v-show="seccion != 'eventos'"
          src="../assets/img/navbar/eventos.png"
        />
        <img
          v-show="seccion == 'eventos'"
          src="../assets/img/navbar/eventos-active.png"
        />
        <label>Eventos</label>
      </div>
      <div
        class="topbar-navbar-row"
        :class="{ 'topbar-navbar-row-active': seccion == 'mensajes' }"
        @click="irA('/mensajes')"
      >
        <img
          v-show="seccion != 'mensajes'"
          src="../assets/img/navbar/mensajes.png"
        />
        <img
          v-show="seccion == 'mensajes'"
          src="../assets/img/navbar/mensajes-active.png"
        />
        <label>Mensajes</label>
      </div>
      <div
        class="topbar-navbar-row"
        :class="{ 'topbar-navbar-row-active': seccion == 'turnos' }"
        @click="irA('/turnos')"
      >
        <img
          v-show="seccion != 'turnos'"
          src="../assets/img/navbar/turnos.png"
        />
        <img
          v-show="seccion == 'turnos'"
          src="../assets/img/navbar/turnos-active.png"
        />
        <label>Turnos</label>
      </div>
      <div
        class="topbar-navbar-row"
        :class="{ 'topbar-navbar-row-active': seccion == 'empresas' }"
        @click="irA('/admin/empresas')"
      >
        <img
          v-show="seccion != 'empresas'"
          src="../assets/img/navbar/empresas.png"
        />
        <img
          v-show="seccion == 'empresas'"
          src="../assets/img/navbar/empresas-active.png"
        />
        <label>Empresas</label>
      </div>
      <div
        class="topbar-navbar-row"
        :class="{ 'topbar-navbar-row-active': seccion == 'usuarios' }"
        @click="irA('/admin/usuarios')"
      >
        <img
          v-show="seccion != 'usuarios'"
          src="../assets/img/navbar/usuarios.png"
        />
        <img
          v-show="seccion == 'usuarios'"
          src="../assets/img/navbar/usuarios-active.png"
        />
        <label>Usuarios</label>
      </div>
      <div
        class="topbar-navbar-row"
        :class="{ 'topbar-navbar-row-active': seccion == 'vigilancias' }"
        @click="irA('/admin/vigilancias')"
      >
        <img
          v-show="seccion != 'vigilancias'"
          src="../assets/img/navbar/vigilancias.png"
        />
        <img
          v-show="seccion == 'vigilancias'"
          src="../assets/img/navbar/vigilancias-active.png"
        />
        <label>Vigilancias</label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Topbar",
  props: {
    seccion: String,
  },
  components: {},
  mounted() {},
  computed: {
    ...mapGetters(["usuario"]),
  },
  methods: {
    irA(text) {
      this.$router.push(text);
    },
    logout() {
      var that = this;
      this.$axios
        .post(this.$serverURL + "/api/user/logout/")
        .then(function () {
          localStorage.removeItem("$token");
          that.$axios.defaults.headers.common["Authorization"] = null;
          that.$emitter.emit("logout");
          that.$router.push("/login");
        })
        .catch(function (response) {
          console.log(response);
        });
    },
  },
};
</script>

<style scoped lang="scss" src="@/assets/css/components/topbar.scss"></style>
